import { Select } from "antd";
import React from "react";

export default function SelectField(props) {
  return (
    <div className="mt-3">
      <label
        htmlFor="assesment-time "
        className="font-primary capitalize text-sm lg:text-base"
      >
        {props?.label}
      </label>
      <Select
        value={props?.value}
        defaultValue={props?.defaultValue}
        onChange={props?.onChange}
        showSearch
        className="md:w-full w-full assessment-validity rounded-md mt-2"
        placeholder={props?.placeholder}
        optionFilterProp="children"
        filterOption={(input: any, option: any) =>
          (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={props?.options}
      >
        I
      </Select>
    </div>
  );
}
