// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.job-card {
  /* width: 420px; */
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  margin: 16px 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.job-card:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.save-icon {
  width: 15px;
  padding-top: 5px;
}

.job-card-header {
  /* / border-bottom: 1px solid #e0e0e0; / */
  padding-bottom: 8px;
  margin-bottom: 8px;
  display: flex;
  position: relative;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.job-card-header h2 {
  margin: 0;
  font-size: 1.5em;
}

.job-card-header h3 {
  margin: 0;
  font-size: 1.2em;
  color: black;
}

.job-card-body p {
  margin: 4px 0;
  color: black;
}

.job-card-footer {
  margin-top: 16px;
  color: black;
}
.text-style{
  max-width: 20ch;
}
.job-card-footer button {
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.job-card-footer button:hover {
  background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/views/authenticateViews/Admin/job/JobCard.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,gBAAgB;EAChB,wCAAwC;EACxC,gCAAgC;AAClC;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,0CAA0C;EAC1C,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,kBAAkB;EAClB,8BAA8B;EAC9B,yCAAyC;AAC3C;;AAEA;EACE,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".job-card {\n  /* width: 420px; */\n  border: 1px solid #ccc;\n  border-radius: 8px;\n  padding: 16px;\n  margin: 16px 4px;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n  transition: box-shadow 0.3s ease;\n}\n\n.job-card:hover {\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);\n}\n\n.save-icon {\n  width: 15px;\n  padding-top: 5px;\n}\n\n.job-card-header {\n  /* / border-bottom: 1px solid #e0e0e0; / */\n  padding-bottom: 8px;\n  margin-bottom: 8px;\n  display: flex;\n  position: relative;\n  flex-direction: row !important;\n  justify-content: space-between !important;\n}\n\n.job-card-header h2 {\n  margin: 0;\n  font-size: 1.5em;\n}\n\n.job-card-header h3 {\n  margin: 0;\n  font-size: 1.2em;\n  color: black;\n}\n\n.job-card-body p {\n  margin: 4px 0;\n  color: black;\n}\n\n.job-card-footer {\n  margin-top: 16px;\n  color: black;\n}\n.text-style{\n  max-width: 20ch;\n}\n.job-card-footer button {\n  background-color: #007bff;\n  color: white;\n  padding: 8px 16px;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.job-card-footer button:hover {\n  background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
