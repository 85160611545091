// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assessment-table .ant-table-pagination-right{
    justify-content: center !important;
}
.assessment-table .ant-table-pagination {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
  }`, "",{"version":3,"sources":["webpack://./src/components/tables/assessmentTable.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;AACtC;AACA;IACI,aAAa;IACb,eAAe;IACf,YAAY;EACd","sourcesContent":[".assessment-table .ant-table-pagination-right{\n    justify-content: center !important;\n}\n.assessment-table .ant-table-pagination {\n    display: flex;\n    flex-wrap: wrap;\n    row-gap: 8px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
