import { Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { getPaginationData } from "../../serviceApi/superAdminApi/DomainApi";
import { searchData } from "../../serviceApi/adminApi/DomainApi";
const PaginationData = (props: any) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeData, setPageSizeData] = useState(8);
  const startIndex = (currentPage - 1) * pageSizeData;
  const endIndex = pageSizeData;

  const getPageAndPageSize = async (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSizeData(pageSize);
  };

  const getPaginationChange = async () => {
    if (props?.getSearchActive) {
      if (props?.name) {
        if (props?.type === "Domain_Approved") {
          props?.paginatedData(
            props?.data.slice(
              (currentPage - 1) * pageSizeData,
              currentPage * pageSizeData
            )
          );
        } else {
          const results = await searchData(
            props?.name,
            props?.userId,
            startIndex,
            endIndex,
            props?.type,
            props?.domainId
          );
          props.paginatedData(results?.data?.searchData);
        }
      }
    } else if (!props?.getSearchActive) {
      if (props?.name) {
        if (props?.type === "Domain_Approved") {
          props?.paginatedData(
            props?.data.slice(
              (currentPage - 1) * pageSizeData,
              currentPage * pageSizeData
            )
          );
        } else {
          const results = await searchData(
            props?.name,
            props?.userId,
            startIndex,
            endIndex,
            props?.type,
            props?.domainId
          );
          props.paginatedData(results?.data?.searchData);
        }
      } else {
        const response = await getPaginationData(
          props.userId,
          startIndex,
          endIndex,
          props.type,
          props.domainId
        );
        if (props.type == "Studentresult") {
          const resultData = response?.data?.paginationData;
          const newArray = resultData.map((m) => [m.name, m]);
          const newMap = new Map(newArray);
          const iterator: any = newMap.values();
          const uniqueResult: any = [...iterator];
          return props.paginatedData(uniqueResult);
        }
        props.paginatedData(response?.data?.paginationData);
      }
    } else {
      const response = await getPaginationData(
        props.userId,
        startIndex,
        endIndex,
        props.type,
        props.domainId
      );

      if (props.type == "Studentresult") {
        const resultData = response?.data?.paginationData;
        const newArray = resultData.map((m) => [m.name, m]);
        const newMap = new Map(newArray);
        const iterator: any = newMap.values();
        const uniqueResult: any = [...iterator];
        return props.paginatedData(uniqueResult);
      }
      props.paginatedData(response?.data?.paginationData);
    }
  };

  useEffect(() => {
    if (currentPage) {
      getPaginationChange();
    }
  }, [props.data, pageSizeData, currentPage, props.userId]);

  useEffect(() => {
    if (props?.getSearchActive) {
      setCurrentPage(1);
      getPaginationChange();
    } else {
      setCurrentPage(1);
      getPaginationChange();
    }
  }, [props?.getSearchActive, props?.name]);

  return (
    <Pagination
      className="common-pagination-main"
      total={props?.data?.length}
      showSizeChanger
      showQuickJumper
      pageSize={pageSizeData}
      onChange={getPageAndPageSize}
      current={currentPage}
      pageSizeOptions={[8, 16, 32, 64, 128]}
    />
  );
};

export default PaginationData;
