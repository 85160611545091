import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  assesmentCreate,
  getAssessmentNameById,
  getSkillNameBySkillId,
} from "../../../../../serviceApi/api";
import { useNavigate, useParams } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { Select } from "antd";
import { InputField } from "../../../../../components/inputFields/InputField";
import Swal from "sweetalert2";
import { getCookie } from "../../../../../utils";
import { containsSpecialChars } from "../../../../../utils/validation";
import {
  getUserValidateSkill,
} from "../../../../../serviceApi/adminApi/SkillApi";
import PageHeading from "../../../../../components/pages/landing/heading/PageHeading";

export const CreateAssessment = () => {
  const navigate = useNavigate();
  const { domainId, categoryId, skillId, assessmentId }: any = useParams();

  const { user } = useAuth0();
  const userId: string = user?.email as string;
  const [name, setName] = useState("");

  const [duration, setDuration] = useState("");
  const [assessmentExpireDate, setAssessmentExpireDate] = useState();
  const [assessmentType, setAssessmentType] = useState<any>();
  const [formStatus, setFormStatus] = useState(true);
  const [skillName, setSkillName] = React.useState();
  const [message, setMessage] = React.useState("");
  const [url, setUrl] = useState("");
  const [coverImagePreview, setCoverImagePreview] = useState<any>();
  const [coverImage, setCoverImage] = useState<any>(null);
  const [description, setDescription] = useState("");
  const [assessmentImageEdit, setAssessmentImageEdit] = useState("");
  const [assessmentNameErr, setAssessmentNameErr] = useState();
  const [descriptionErr, setDescriptionErr] = useState("");
  const [durationErr, setDurationErr] = useState("");
  const [isAuthenticate, setIsAuthenticate] = useState(false);

  const saveAssessment = async () => {
    const createAssesment = await assesmentCreate({
      name,
      duration,
      skillId,
      assessmentId,
      userId,
      url,
      description,
      expire: assessmentExpireDate,
      assessmentType,
    });
    if (createAssesment?.data?.createAssessment?.status) {
      setMessage("This assessment name exist");
    } else if (createAssesment?.data?.createAssessment?.uuid) {
      const formData = new FormData();
      formData.append("image", coverImage);
      formData.append("uuid", createAssesment?.data?.createAssessment?.uuid);
      formData.append("type", "assessment");
      const { data }: any = await axios.post(
        `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
        formData,
        {
          headers: {
            enctype: "multipart/form-data",
            Authorization: `Bearer ${getCookie("token")}`,
          },
        }
      );
      if (data?.status === true) {
        navigate(`/subadmin/domain/skill/${skillId}/assessment/list`);
      } else {
        Swal.fire({
          icon: "error",
          title: "Something went wrong please try again!",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Invalid data remove symbols",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const editAssessment = async (e, assessmentId, skillId) => {
    const createAssesment = await assesmentCreate({
      name,
      duration: duration.toString(),
      skillId,
      assessmentId,
      userId,
      url,
      description,
      expire: assessmentExpireDate,
      assessmentType,
    });
    if (createAssesment?.data?.createAssessment?.message) {
      if (coverImage) {
        const formData = new FormData();
        formData.append("image", coverImage);
        formData.append("uuid", assessmentId);
        formData.append("type", "assessment");
        const { data }: any = await axios.post(
          `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
          formData,
          {
            headers: {
              enctype: "multipart/form-data",
              Authorization: `Bearer ${getCookie("token")}`,
            },
          }
        );
        if (data?.status === true) {
          navigate(`/subadmin/domain/skill/${skillId}/assessment/list`);
        } else {
          Swal.fire({
            icon: "error",
            title: "Something went wrong please try again!",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      } else {
        navigate(`/subadmin/domain/skill/${skillId}/assessment/list`);
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Invalid data remove symbols",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const getAssessmentDetail = async (assessmentId) => {
    const response = await getAssessmentNameById(assessmentId);
    setName(response?.data.assessmentNameById[0].name);
    setDuration(response?.data.assessmentNameById[0].duration);
    setAssessmentExpireDate(response?.data.assessmentNameById[0].expire);
    setUrl(response?.data.assessmentNameById[0].url);
    setDescription(response?.data.assessmentNameById[0].description);
    setAssessmentImageEdit(response?.data.assessmentNameById[0].image);
    setAssessmentType(response?.data.assessmentNameById[0].type);
  };

  const getSkillName = async () => {
    const response = await getSkillNameBySkillId(skillId);
    setSkillName(response?.data.skillNameById[0].name);
  };
  const handleChange = (newValue: any) => {
    setAssessmentExpireDate(newValue);
  };

  const handleType = async (e: string) => {
    setAssessmentType(e);
  };
  const deleteCoverImage = () => {
    setCoverImagePreview(URL.revokeObjectURL(coverImagePreview));
    setCoverImage(null);
  };
  const DomainImageUpload = (
    event: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const selectedFiles = event.target.files[0];
    if (selectedFiles.size >= 2097152) {
      Swal.fire({
        icon: "warning",
        title: "Image size should be max 2MB.",
        showConfirmButton: false,
        timer: 3000,
      });
    } else if (
      selectedFiles.type === "image/png" ||
      selectedFiles.type === "image/jpg" ||
      selectedFiles.type === "image/jpeg"
    ) {
      setCoverImage(selectedFiles);
      setCoverImagePreview(URL.createObjectURL(selectedFiles));
    } else {
      Swal.fire({
        icon: "warning",
        title: "Image formate should be png,jpg,jpeg",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const getUserValidateSkillApi = async () => {
    const isValidate = await getUserValidateSkill(userId, skillId);
    if (isValidate?.data?.skillValidateUser.length > 0) {
      if (assessmentId) {
        getAssessmentDetail(assessmentId);
      }
      getSkillName();
      setIsAuthenticate(true);
    } else {
      setIsAuthenticate(false);
      navigate("/404");
    }
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
    if (
      e.target.value?.trim()?.length > 0 &&
      e.target.value?.trim()?.length <= 200
    ) {
      setDescriptionErr("");
    } else {
      setDescriptionErr("Description should be between 1 and 200 characters");
    }
  };

  useEffect(() => {
    getUserValidateSkillApi();
  }, [assessmentId]);

  useEffect(() => {
    if (
      name &&
      !containsSpecialChars(name) &&
      name?.trim()?.length <= 60 &&
      name?.trim()?.length >= 2 &&
      duration &&
      Number(duration) <= 180 &&
      Number(duration) > 0 &&
      assessmentExpireDate &&
      (coverImage || assessmentImageEdit) &&
      description?.trim()?.length > 0 &&
      description?.trim()?.length <= 200 &&
      assessmentType
    ) {
      setFormStatus(false);
    } else setFormStatus(true);
  }, [
    name,
    duration,
    description,
    coverImage,
    assessmentImageEdit,
    assessmentExpireDate,
    assessmentType,
  ]);

  const breadcrumbData: any = [
    {
      path: "/subadmin/AllDomains/list",
      text: "Dashboard",
    },
    { text: "skill" },
    {
      path: `/subadmin/skills/list`,
      text: skillName,
    },
    { text: assessmentId ? "Edit Assessment" : "Create New Assessment" },
  ];

  return (
    <>
      {isAuthenticate && (
        <div className="w-full max-h-[86vh] overflow-y-auto mt-5 lg:p-10 p-5">
          <PageHeading
            breadcrumb={breadcrumbData}
            title={assessmentId ? "Assessment Edit" : "Assessment Create"}
          />
          <div className="mt-5 h-26 p-6 rounded-lg bg-white border border-gray-200  shadow-xl lg:w-full">
            <div className="grid lg:grid-cols-2 gap-5 lg:gap-x-20">
              <div>
                <InputField
                  id="Assessment name"
                  placeholder="Enter Assessment Name.."
                  type="text"
                  value={name}
                  onChange={setName}
                  containsSpecialCharsMsg={setAssessmentNameErr}
                />
                <span className="text-red-500 absolute mt-[10px]">
                  {assessmentNameErr}
                </span>
              </div>
              <div>
                <InputField
                  id="Assessment duration in minutes"
                  placeholder="e.g. 5"
                  type="number"
                  pattern="[0-9]"
                  value={duration}
                  onChange={setDuration}
                  containsWeightageMsg={setDurationErr}
                />
                <span className="text-red-500 absolute mt-[6px]">
                  {durationErr}
                </span>
              </div>
              <div className="w-full mt-2">
                <label
                  htmlFor="assesment-time "
                  className="font-primary capitalize text-sm lg:text-base"
                >
                  Select assessment validity plan
                  <span className="text-red-600 text-lg"> *</span>
                </label>
                <div className="w-full lg:w-full mt-2">
                  <Select
                    value={assessmentExpireDate}
                    onChange={handleChange}
                    showSearch
                    className="md:w-full w-full assessment-validity rounded-md"
                    placeholder="Select Plan"
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      (option?.label?.toLowerCase() ?? "").includes(input?.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={[
                      {
                        value: "week",
                        label: "Weekly",
                      },
                      {
                        value: "month",
                        label: "Monthly",
                      },
                      {
                        value: "quaterly",
                        label: "Quaterly",
                      },
                      {
                        value: "half yearly",
                        label: "Half-Yearly",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="w-full mt-2">
                <InputField
                  id="Website URL"
                  placeholder="e.g. www.example.com"
                  type="text1"
                  value={url}
                  onChange={setUrl}
                  containsSpecialCharsMsg=""
                />
              </div>
              <div className="w-full mt-2">
                <label
                  htmlFor="assesment-time1"
                  className="font-primary capitalize text-sm lg:text-base"
                >
                  Select assessment Type
                  <span className="text-red-600 text-lg"> *</span>
                </label>
                <div className="w-full lg:w-full mt-2">
                  <Select
                    className="md:w-full w-full assessment-validity rounded-md"
                    value={assessmentType}
                    onChange={handleType}
                    placeholder="Select Type"
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={[
                      {
                        value: "public",
                        label: "Public",
                      },
                      {
                        value: "private",
                        label: "Private",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="flex flex-col mt-2">
                <label className="text-base font-primary lg:text-base pb-0  mb-2   text-black ">
                  Assessment image <span className="text-red-500">*</span>
                </label>
                {coverImagePreview ? (
                  <div className="h-44 border relative  mb-10 w-44">
                    <img
                      className="h-full w-full p-1 rounded-lg object-cover object-center"
                      src={coverImagePreview}
                      alt={coverImagePreview}
                    />
                    <div
                      onClick={deleteCoverImage}
                      className="bg-black cursor-pointer  w-8 h-8 flex items-center justify-center absolute top-0 right-0 rounded-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 mx-auto text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </div>
                  </div>
                ) : (
                  <button type="submit" className="h-44 w-44">
                    <div className="flex items-center justify-center">
                      <label
                        data-testid="assessmentImage"
                        htmlFor="dropzone-file"
                        className="flex flex-col items-center justify-center w-full h-auto border-2 bg-gray-50 border-gray-300 border-dashed rounded-lg cursor-pointer  dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            aria-hidden="true"
                            className="w-10 h-10 mb-3 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p className="mb-2 text-sm font-primary text-gray-500 dark:text-gray-400">
                            <span className="font-semibold font-primary">
                              Click to upload
                            </span>{" "}
                          </p>
                          <p className="text-xs text-center text-gray-500 dark:text-gray-400">
                            PNG, JPG,JPEG (MAX Size. 2MB)
                          </p>
                        </div>
                        {assessmentImageEdit ? (
                          <div className="group  -mt-44 relative bg-white p-1 rounded-xl border border-dashed border-black">
                            <div className="h-full text-2xl w-full group-hover:opacity-100 opacity-0 absolute z-20  text-white flex items-center justify-center inset-0">
                              <svg
                                aria-hidden="true"
                                className="w-10 h-10 mb-3 text-white"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                ></path>
                              </svg>
                              <p className="mb-2 text-sm text-text-white dark:text-gray-400">
                                <span className="font-semibold">
                                  Click to upload
                                </span>{" "}
                              </p>
                            </div>
                            <div className="absolute group-hover:bg-black opacity-40 text-center w-full h-full rounded-xl  text-white"></div>
                            <img
                              className="w-44 h-44 object-cover object-center rounded-xl"
                              src={`${process.env.REACT_APP_MEDIA_URL}/api/media/download${assessmentImageEdit}`}
                              alt={assessmentImageEdit}
                            />
                          </div>
                        ) : null}
                        <input
                          id="dropzone-file"
                          onChange={(e) => DomainImageUpload(e)}
                          type="file"
                          className="hidden"
                        />
                      </label>
                    </div>
                  </button>
                )}
              </div>
              <div className="lg:mt-[-112px]  mt-2 relative">
                <label
                  htmlFor="assesment-time "
                  className="font-primary capitalize text-sm lg:text-base"
                >
                  Assessment Description
                  <span className="text-red-600 text-lg"> *</span>
                </label>
                <textarea
                  className="lg:p-4 h-36 p-2 text-sm lg:mt-[-112] border border-gray-300 w-full focus:outline-none rounded-lg"
                  name=""
                  id=""
                  value={description}
                  onChange={(e) => handleDescription(e)}
                  placeholder="Assessment description..."
                ></textarea>

                <span>{description?.length}/200</span>

                <p className="text-red-500 absolute mt-[10px]">
                  {descriptionErr}
                </p>
              </div>
            </div>
            <span style={{ color: "red" }}>{message}</span>
            <div>
              <button
                onClick={
                  assessmentId
                    ? (e) => {
                        editAssessment(e, assessmentId, skillId);
                      }
                    : saveAssessment
                }
                disabled={formStatus ? true : false}
                className={`font-primary px-8  py-2 mt-12 text-white text-lg bg-gray-900 rounded-full ${
                  formStatus ? "opacity-30 cursor-not-allowed" : "opacity-100"
                } focus:outline-none`}
              >
                {assessmentId ? "Update" : "Create"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
