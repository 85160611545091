import { Drawer, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { PrimaryButton } from "../buttons/PrimaryButton";
import SelectField from "../selectField/SelectField";
import { getEventFilterData } from "../../serviceApi/adminApi/EventApi";

export default function EventDataFilter(props) {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const [eventStatus, setEventStatus] = useState("");

  const handleOnChange = async (e) => {
    props.input(e);
    setEventStatus(e);
  };

  const getFilterEvent = async () => {
    const filteredEvents = await getEventFilterData(
      eventStatus,
      `${Date.now()}`
    );
    props.isFilterActive(true);
    props.filterData(filteredEvents.data.getEventFilter);
  };

  const handleReset = () => {
    setEventStatus("");
  };

  const options = [
    {
      value: "expired",
      label: "Expired",
    },
    {
      value: "live",
      label: "Live",
    },
    {
      value: "upcoming",
      label: "Upcoming",
    },
  ];

  return (
    <div className="relative">
      <Tooltip title="Filter Events">
        <div>
          <button
            onClick={showDrawer}
            className="text-gray-700 focus:outline-none  font-semibold  mt-4 flex gap-x-2 lg:mt-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill="currentColor"
              className="text-gray-700"
              width={20}
              height={20}
            >
              <path d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z" />
            </svg>
            Filter{" "}
          </button>
        </div>
      </Tooltip>

      <Drawer title="Filter Events by" onClose={onClose} open={open}>
        <SelectField
          placeholder="enter event type"
          options={options}
          value={eventStatus}
          onChange={(e) => handleOnChange(e)}
          label="Select event status"
        />

        <div className="flex gap-5 mt-2">
          <PrimaryButton
            text="Reset"
            onclick={handleReset}
            disabledStatus={eventStatus ? false : true}
            className={`${
              eventStatus
                ? "bg-black hover:bg-transparent hover:border-black border-black hover:text-black cursor-pointer"
                : "bg-gray-300 cursor-not-allowed border-gray-300"
            }`}
          />
          <PrimaryButton
            text="Apply"
            onclick={getFilterEvent}
            disabledStatus={eventStatus ? false : true}
            className={`${
              eventStatus
                ? "bg-black hover:bg-transparent hover:border-black border-black hover:text-black cursor-pointer"
                : "bg-gray-300 cursor-not-allowed border-gray-300"
            }`}
          />
        </div>
      </Drawer>
    </div>
  );
}
