export const jobRoleData= [
    { value: "Software Engineer", label: "Software Engineer" },
    { value: "Data Scientist", label: "Data Scientist" },
    { value: "Product Manager", label: "Product Manager" },
    { value: "UX/UI Designer", label: "UX/UI Designer" },
    { value: "Marketing Manager", label: "Marketing Manager" },
    { value: "Sales Executive", label: "Sales Executive" },
    { value: "Financial Analyst", label: "Financial Analyst" },
    { value: "Human Resources Manager", label: "Human Resources Manager" },
    { value: "Operations Manager", label: "Operations Manager" },
    { value: "Project Manager", label: "Project Manager" },
    { value: "Business Analyst", label: "Business Analyst" },
    { value: "Network Administrator", label: "Network Administrator" },
    { value: "System Administrator", label: "System Administrator" },
    { value: "Graphic Designer", label: "Graphic Designer" },
    { value: "Content Writer", label: "Content Writer" },
    {
      value: "Customer Service Representative",
      label: "Customer Service Representative",
    },
    { value: "Quality Assurance Tester", label: "Quality Assurance Tester" },
    {
      value: "Technical Support Specialist",
      label: "Technical Support Specialist",
    },
    { value: "DevOps Engineer", label: "DevOps Engineer" },
    { value: "Cybersecurity Specialist", label: "Cybersecurity Specialist" },
    { value: "Database Administrator", label: "Database Administrator" },
    { value: "Architect", label: "Architect" },
    { value: "Civil Engineer", label: "Civil Engineer" },
    { value: "Mechanical Engineer", label: "Mechanical Engineer" },
    { value: "Electrical Engineer", label: "Electrical Engineer" },
    { value: "Environmental Scientist", label: "Environmental Scientist" },
    { value: "Biotechnologist", label: "Biotechnologist" },
    { value: "Pharmaceutical Researcher", label: "Pharmaceutical Researcher" },
    { value: "Clinical Psychologist", label: "Clinical Psychologist" },
    { value: "Social Worker", label: "Social Worker" },
    { value: "Teacher", label: "Teacher" },
    { value: "Professor", label: "Professor" },
    { value: "School Principal", label: "School Principal" },
    { value: "Research Scientist", label: "Research Scientist" },
    { value: "Medical Doctor", label: "Medical Doctor" },
    { value: "Nurse", label: "Nurse" },
    { value: "Physical Therapist", label: "Physical Therapist" },
    { value: "Pharmacist", label: "Pharmacist" },
    { value: "Dentist", label: "Dentist" },
    { value: "Veterinarian", label: "Veterinarian" },
    { value: "Legal Counsel", label: "Legal Counsel" },
    { value: "Paralegal", label: "Paralegal" },
    { value: "Judge", label: "Judge" },
    { value: "Police Officer", label: "Police Officer" },
    { value: "Firefighter", label: "Firefighter" },
    { value: "Journalist", label: "Journalist" },
    { value: "Photographer", label: "Photographer" },
    { value: "Event Planner", label: "Event Planner" },
    { value: "Chef", label: "Chef" },
    { value: "Restaurant Manager", label: "Restaurant Manager" },
    { value: "Real Estate Agent", label: "Real Estate Agent" },
    { value: "Construction Manager", label: "Construction Manager" },
    { value: "Electrician", label: "Electrician" },
    { value: "Plumber", label: "Plumber" },
    { value: "Carpenter", label: "Carpenter" },
    { value: "Automotive Technician", label: "Automotive Technician" },
    { value: "Pilot", label: "Pilot" },
    { value: "Flight Attendant", label: "Flight Attendant" },
    { value: "Logistics Coordinator", label: "Logistics Coordinator" },
    { value: "Supply Chain Manager", label: "Supply Chain Manager" },
    { value: "Economist", label: "Economist" },
    { value: "Actuary", label: "Actuary" },
    { value: "Statistician", label: "Statistician" },
    {
      value: "Public Relations Specialist",
      label: "Public Relations Specialist",
    },
    { value: "Advertising Executive", label: "Advertising Executive" },
    { value: "Copywriter", label: "Copywriter" },
    { value: "Editor", label: "Editor" },
    { value: "Publisher", label: "Publisher" },
    { value: "Librarian", label: "Librarian" },
    { value: "Museum Curator", label: "Museum Curator" },
    { value: "Artist", label: "Artist" },
    { value: "Musician", label: "Musician" },
    { value: "Actor", label: "Actor" },
    { value: "Film Director", label: "Film Director" },
    { value: "Producer", label: "Producer" },
    { value: "Animator", label: "Animator" },
    { value: "Game Developer", label: "Game Developer" },
    { value: "Sports Coach", label: "Sports Coach" },
    { value: "Athletic Trainer", label: "Athletic Trainer" },
    { value: "Fitness Instructor", label: "Fitness Instructor" },
    { value: "Nutritionist", label: "Nutritionist" },
    { value: "Dietitian", label: "Dietitian" },
    { value: "Occupational Therapist", label: "Occupational Therapist" },
    { value: "Speech Therapist", label: "Speech Therapist" },
    { value: "Audiologist", label: "Audiologist" },
    { value: "Chiropractor", label: "Chiropractor" },
    { value: "Optometrist", label: "Optometrist" },
    { value: "Radiologist", label: "Radiologist" },
    { value: "Surgeon", label: "Surgeon" },
    { value: "Anesthesiologist", label: "Anesthesiologist" },
    { value: "Cardiologist", label: "Cardiologist" },
    { value: "Dermatologist", label: "Dermatologist" },
    { value: "Endocrinologist", label: "Endocrinologist" },
    { value: "Gastroenterologist", label: "Gastroenterologist" },
    { value: "Neurosurgeon", label: "Neurosurgeon" },
    { value: "Orthopedic Surgeon", label: "Orthopedic Surgeon" },
    { value: "Pediatrician", label: "Pediatrician" },
    { value: "Psychiatrist", label: "Psychiatrist" },
    { value: "Radiologic Technologist", label: "Radiologic Technologist" },
    { value: "Respiratory Therapist", label: "Respiratory Therapist" },
    { value: "Veterinary Technician", label: "Veterinary Technician" },
    { value: "Zoologist", label: "Zoologist" },
    { value: "Marine Biologist", label: "Marine Biologist" },
    { value: "Forester", label: "Forester" },
    { value: "Park Ranger", label: "Park Ranger" },
    { value: "Geologist", label: "Geologist" },
    { value: "Meteorologist", label: "Meteorologist" },
    { value: "Astronomer", label: "Astronomer" },
    { value: "Astrophysicist", label: "Astrophysicist" },
    { value: "Chemist", label: "Chemist" },
    { value: "Physicist", label: "Physicist" },
    { value: "Mathematician", label: "Mathematician" },
    { value: "Software Architect", label: "Software Architect" },
    { value: "IT Consultant", label: "IT Consultant" },
    { value: "Blockchain Developer", label: "Blockchain Developer" },
    { value: "AI Specialist", label: "AI Specialist" },
    { value: "Robotics Engineer", label: "Robotics Engineer" },
    { value: "Cloud Engineer", label: "Cloud Engineer" },
    {
      value: "Renewable Energy Consultant",
      label: "Renewable Energy Consultant",
    },
    { value: "Urban Planner", label: "Urban Planner" },
    { value: "Fashion Designer", label: "Fashion Designer" },
    { value: "Interior Designer", label: "Interior Designer" },
    { value: "Industrial Designer", label: "Industrial Designer" },
    { value: "Logistics Manager", label: "Logistics Manager" },
    { value: "Supply Chain Analyst", label: "Supply Chain Analyst" },
    {
      value: "Business Development Manager",
      label: "Business Development Manager",
    },
    { value: "E-commerce Specialist", label: "E-commerce Specialist" },
    {
      value: "Digital Marketing Specialist",
      label: "Digital Marketing Specialist",
    },
    { value: "SEO Specialist", label: "SEO Specialist" },
    { value: "Social Media Manager", label: "Social Media Manager" },
    { value: "Content Strategist", label: "Content Strategist" },
    { value: "Technical Writer", label: "Technical Writer" },
    { value: "Web Developer", label: "Web Developer" },
    { value: "Mobile App Developer", label: "Mobile App Developer" },
    { value: "Machine Learning Engineer", label: "Machine Learning Engineer" },
    { value: "Data Engineer", label: "Data Engineer" },
    { value: "Bioinformatician", label: "Bioinformatician" },
    {
      value: "Clinical Research Coordinator",
      label: "Clinical Research Coordinator",
    },
    {
      value: "Regulatory Affairs Specialist",
      label: "Regulatory Affairs Specialist",
    },
    { value: "Patent Examiner", label: "Patent Examiner" },
    { value: "Forensic Scientist", label: "Forensic Scientist" },
    { value: "Crime Scene Investigator", label: "Crime Scene Investigator" },
    { value: "Intelligence Analyst", label: "Intelligence Analyst" },
    { value: "Diplomat", label: "Diplomat" },
    { value: "Policy Analyst", label: "Policy Analyst" },
    { value: "Political Consultant", label: "Political Consultant" },
    { value: "Campaign Manager", label: "Campaign Manager" },
    { value: "Public Policy Specialist", label: "Public Policy Specialist" },
    { value: "Nonprofit Manager", label: "Nonprofit Manager" },
    { value: "Fundraising Coordinator", label: "Fundraising Coordinator" },
    {
      value: "Community Outreach Coordinator",
      label: "Community Outreach Coordinator",
    },
    { value: "Volunteer Coordinator", label: "Volunteer Coordinator" },
    { value: "Grant Writer", label: "Grant Writer" },
    { value: "Program Director", label: "Program Director" },
    { value: "Youth Worker", label: "Youth Worker" },
    { value: "Elder Care Specialist", label: "Elder Care Specialist" },
    { value: "Mental Health Counselor", label: "Mental Health Counselor" },
    { value: "School Counselor", label: "School Counselor" },
    { value: "Career Counselor", label: "Career Counselor" },
    { value: "Rehabilitation Counselor", label: "Rehabilitation Counselor" },
    { value: "Substance Abuse Counselor", label: "Substance Abuse Counselor" },
    {
      value: "Marriage and Family Therapist",
      label: "Marriage and Family Therapist",
    },
    { value: "Clinical Social Worker", label: "Clinical Social Worker" },
    { value: "Medical Social Worker", label: "Medical Social Worker" },
    {
      value: "Industrial-Organizational Psychologist",
      label: "Industrial-Organizational Psychologist",
    },
    { value: "School Psychologist", label: "School Psychologist" },
    { value: "Research Assistant", label: "Research Assistant" },
    { value: "Laboratory Technician", label: "Laboratory Technician" },
    {
      value: "Clinical Laboratory Scientist",
      label: "Clinical Laboratory Scientist",
    },
    { value: "Pathologist", label: "Pathologist" },
    { value: "Immunologist", label: "Immunologist" },
    { value: "Geneticist", label: "Geneticist" },
    { value: "Molecular Biologist", label: "Molecular Biologist" },
    { value: "Biochemist", label: "Biochemist" },
    { value: "Biophysicist", label: "Biophysicist" },
    { value: "Bioengineer", label: "Bioengineer" },
    {
      value: "Pharmaceutical Sales Representative",
      label: "Pharmaceutical Sales Representative",
    },
    {
      value: "Medical Device Sales Representative",
      label: "Medical Device Sales Representative",
    },
    { value: "Healthcare Administrator", label: "Healthcare Administrator" },
    { value: "Hospital Administrator", label: "Hospital Administrator" },
    { value: "Clinic Manager", label: "Clinic Manager" },
    {
      value: "Health Information Technician",
      label: "Health Information Technician",
    },
    { value: "Medical Coder", label: "Medical Coder" },
    {
      value: "Medical Billing Specialist",
      label: "Medical Billing Specialist",
    },
    { value: "Patient Care Coordinator", label: "Patient Care Coordinator" },
    { value: "Other", label: "Other" },
  ];


export const perksBenefits = [
    { value: "Competitive salary", label: "Competitive salary" },
    { value: "Healthcare coverage", label: "Comprehensive healthcare" },
    { value: "Flexible work options", label: "Flexible work options" },
    {
      value: "Opportunities for professional growth",
      label: "Opportunities for professional growth",
    },
    { value: "Work life balance", label: " work-life balance" },
    {
      value: "Collaborative team work environment",
      label: "Collaborative work environment",
    },
    { value: "Modern workspace", label: "Modern workspace" },
    {
      value: "Diverse inclusive culture",
      label: "Diverse and inclusive culture ",
    },
    { value: "Performance based rewards", label: "Performance-based rewards" },
    {
      value: "Social responsibility initiatives",
      label: " social responsibility initiatives",
    },
  ];

export const qualification = [
    {
      value: "high school diploma ",
      label: "High School Diploma or equivalent",
    },
    { value: "associate degree", label: "Associate Degree " },
    { value: "bachelor degree", label: "Bachelor's Degree " },
    { value: "master degree", label: "Master's Degree " },
    {
      value: "professional certification",
      label: "Professional certification",
    },
    {
      value: "any graduate",
      label: "Any graduate ",
    },
  ];


export const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: ["right", "center", "justify"] }],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["image"],
      [{ color: ["red", "#785412"] }],
      [{ background: ["red", "#785412"] }],
    ],
  };

export const formats = [
    "header",
    "bold",
    "italic",
    "symbol",
    "script",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
    "script",
    "size",
    "color",
    "clean",
  ];