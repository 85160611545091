import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "apollo-link-context";
import gql from "graphql-tag";
import { getCookie } from "../../utils";

interface DefaultOptions {
  watchQuery: any;
  query: any;
}

interface DomainInput {
  name: string;
  description: string;
}
const httpLink: any = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_URL}`,
  fetch,
});

const authLink: any = setContext((_, { headers }) => {
  const token = getCookie("token");

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

export const deleteDomain = async (domainId: string) => {
  const query = gql`
    query deleteDomain($domainId: String!) {
      deleteDomain(domainId: $domainId) {
        uuid
        id
        domainId
        message
      }
    }
  `;
  const variables = {
    domainId,
  };
  try {
    const result = await client.query({
      query,
      variables,
    });
    return result;
  } catch (err) {
    console.log(" Request DomainGqlQuery graphql error occurred", err);
  }
};

export const getDomains = async (userId: string) => {
  const query = gql`
    query Domains($userId: String!) {
      domains(userId: $userId) {
        uuid
        name
        id
        domain_type
        domain_Amount
        status
        description
        image
        userId
        message
      }
    }
  `;

  try {
    const result = await client.query({
      query,
      variables: { userId },
    });

    return result;
  } catch (err) {
    console.log(
      "getDomainGqlQuery graphql error occurred",
      JSON.stringify(err)
    );
  }
};
export const getApprovedDomain = async (userId: string, domainId: string) => {
  const query = gql`
    query approvedDomain($userId: String!, $domainId: String!) {
      approvedDomain(userId: $userId, domainId: $domainId) {
        uuid
        name
        id
        domainId
        domain_Amount
        status
        description
        image
        userId
        message
      }
    }
  `;

  try {
    const result = await client.query({
      query,
      variables: { userId, domainId },
    });

    return result;
  } catch (err) {
    console.log(
      "getDomainGqlQuery graphql error occurred",
      JSON.stringify(err)
    );
  }
};
export const getPaginationData = async (
  userId: string | undefined,
  start: string | number,
  end: string | number,
  type: string | undefined,
  uuid: string | undefined
) => {

  const query = gql`
    query paginationData(
      $userId: String!
      $start: ID!
      $end: ID!
      $type: String!
      $uuid: String!
    ) {
      paginationData(
        userId: $userId
        start: $start
        end: $end
        type: $type
        uuid: $uuid
      ) {
        uuid
        name
        id
        domain_type
        domain_Amount
        status
        description
        image
        userId
        message
        categoryId
        assessmentId
        url
        duration
        event_end_date
        event_start_date
      }
    }
  `;

  try {
    const result = await client.query({
      query,
      variables: { userId, start, end, type, uuid },
    });

    return result;
  } catch (err) {
    console.log("paginationData graphql error occurred", JSON.stringify(err));
  }
};

export const getDomainNameById = async (domainId: string | undefined) => {
  const query = gql`
    query domainNameById($domainId: ID!) {
      domainNameById(domainId: $domainId) {
        id
        uuid
        name
        description
        image
      }
    }
  `;
  const variables = {
    domainId,
  };
  try {
    const request = await client.query({
      query,
      variables,
    });
    return request;
  } catch (err) {
    console.log(
      "getDomainNameById graphql error occurred",
      JSON.stringify(err)
    );
  }
};

export const getDomainsRequest = async (userId: string) => {
  const query = gql`
    query domainsRequest($userId: String!) {
      domainsRequest(userId: $userId) {
        uuid
        id
        domainId
        userId
        status
        name
        image
      }
    }
  `;

  try {
    const result = await client.query({
      query,
      variables: { userId },
    });
    return result;
  } catch (err) {
    console.log(
      "getDomainRequestGqlQuery graphql error occurred",
      JSON.stringify(err)
    );
  }
};

export const approvedRequest = async (domainId: string, userId: string) => {
  const query = gql`
    query approvedDomainRequest($domainId: String!, $userId: String!) {
      approvedDomainRequest(domainId: $domainId, userId: $userId) {
        uuid
        id
        domainId
        userId
        status
        name
      }
    }
  `;

  try {
    const result = await client.query({
      query,
      variables: { domainId, userId },
    });
    return result;
  } catch (err) {
    console.log("approvedRequest graphql error occurred", err);
  }
};

export const denyRequest = async (domainId: string, userId: string) => {
  const query = gql`
    query denyDomainRequest($domainId: String!, $userId: String!) {
      denyDomainRequest(domainId: $domainId, userId: $userId) {
        uuid
        id
        domainId
        userId
        status
        name
      }
    }
  `;

  try {
    const result = await client.query({
      query,
      variables: { domainId, userId },
    });
    return result;
  } catch (err) {
    console.log("approvedRequest graphql error occurred", err);
  }
};

export const getNotification = async (userId: string | undefined) => {
  const query = gql`
    query getNotification($userId: String!) {
      getNotification(userId: $userId) {
        uuid
        body
        from
        to
      }
    }
  `;

  try {
    const result = await client.query({
      query,
      variables: { userId },
    });
    return result;
  } catch (err) {
    console.log("getNotification graphql error occurred", JSON.stringify(err));
  }
};

export const createDomain = async (domainInput: DomainInput) => {
  const createDomain = gql`
    mutation createDomain($domainInput: DomainInput!) {
      createDomain(domainInput: $domainInput) {
        uuid
        id
        name
        domain_Amount
        domain_type
        plan_type
        description
        status
      }
    }
  `;
  try {
    const result = await client.mutate({
      mutation: createDomain,
      variables: { domainInput },
    });
    return result;
  } catch (err) {
    console.log("createDomain graphql error occurred", err);
    return { data: err, status: 0 };
  }
};


export const updateDomainData = async (
  domainId: string | undefined,
  name: string,
  description: string
) => {
  const updateDomain = gql`
    mutation updateDomain(
      $domainId: String!
      $description: String!
      $name: String!
    ) {
      updateDomain(
        domainId: $domainId
        description: $description
        name: $name
      ) {
        id
        message
      }
    }
  `;
  try {
    const result = await client.mutate({
      mutation: updateDomain,
      variables: { domainId, description, name },
    });
    return result;
  } catch (err) {
    return { data: err, status: 0 };
  }
};
