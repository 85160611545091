import React, { Fragment, useEffect, useState } from "react";
import date from "date-and-time";
import { useAuth0 } from "@auth0/auth0-react";
import {
  createfavouriteAssessment,
  totalAssessment,
} from "../../../../serviceApi/api";
import { AssessmentCard } from "../studentCards/AssessmentCard";
import { CardSkeletonLoader } from "../../../../components/skeleton/CardSkeleton";
import { SearchFilterInput } from "../../../../components/search/SearchInput";
import PaginationData from "../../../../components/pagination/Pagination";
import { BreadcrumbComp } from "../../../../components/Breadcrumb/Breadcrumb";
import StudentDataFilter from "../../../../components/filter/StudentDataFilter";
import { favassessmentDelete } from "../../../../serviceApi/adminApi/AssessmentApi";

interface FavouriteAssessmentsInput {
  assessmentId: string;
  userId: string;
}
export const AllAssessments = () => {
  const { user }: any = useAuth0();
  const userId = user?.email;

  const currentDate = new Date();
  const dateFormat: any = date.format(currentDate, "YYYY-MM-DD");
  const [assessmentList, setAssessmentList] = useState([]);
  const [, setActive] = useState(false);
  const [dateToday, setDate] = useState<any>();
  const [, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [paginatedData, setPaginatedData] = useState([]);
  const [getSearch, setGetSearch] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [input, setInput] = useState("");
  const [addResponse, setAddResponse] = useState(false);
  const getAssesmentsListBySkillId = async () => {
    if (userId) {
      const assessments = await totalAssessment();
      setTimeout(() => {
        if (assessments?.data?.assessmentsNumber) {
          setIsLoading(false);
          const data = assessments?.data?.assessmentsNumber.filter((item) => {
            if (item?.type == "public") {
              return item;
            }
          });
          setAssessmentList(data);
          setDate(dateFormat);
        }
      }, 500);
    }
  };

  const getSkillId = async (event) => {
    setInputValue(event.target.value);
  };

  const getSearchContent = (data: any, getClick) => {
    if (data?.length > 0 && typeof data != "string") {
      if (getClick) {
        setGetSearch(true);
        setPaginatedData(data);
        setIsSearch(true);
        setActive(true);
        setAssessmentList(data);
      } else {
        setPaginatedData(data);
        setGetSearch(true);
        setIsSearch(!isSearch);
        setAssessmentList(data);
      }
    } else if (data === "value not filled" && typeof data === "string") {
      getAssesmentsListBySkillId();
      setPaginatedData([]);
      setGetSearch(true);
      setIsSearch(false);
      setInput("");
    } else if (data === "not found data" && typeof data === "string") {
      setPaginatedData([]);
      setGetSearch(false);
      setIsSearch(!isSearch);
      getAssesmentsListBySkillId();
    }
  };

  const addFavourites = async (uuid) => {
    const data: FavouriteAssessmentsInput = {
      userId,
      assessmentId: uuid,
    };
    const result = await createfavouriteAssessment(data);

    if (result?.data?.createfavouriteAssessment?.uuid) {
      getAssesmentsListBySkillId();
    }
  };
  const removeFavourites = async (uuid) => {
    const result = await favassessmentDelete(uuid, userId);
    getAssesmentsListBySkillId();
  };

  useEffect(() => {
    getAssesmentsListBySkillId();
  }, [userId, addResponse]);

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  const cleanUp = () => {
    getAssesmentsListBySkillId();
  };

  const studentbreadcrumbData = [
    {
      path: "/student/dashboard",
      text: "Dashboard",
    },
    {
      text: "All Assessments",
    },
  ];
  return (
    <>
      <div className="w-full max-h-[86vh] overflow-y-auto lg:p-10 p-5">
        <h1 className="text-2xl pb-0 mb-0  font-primary  capitalize text-black-500 font-semibold">
          Select Assessment to Continue
        </h1>

        <div className="mb-4">
          <BreadcrumbComp breadcrumbData={studentbreadcrumbData} />
        </div>
        {/* filer added */}
        <div className="mb-5 flex flex-row items-center justify-between gap-x-4 ml-auto lg:w-80 w-full">
          {assessmentList?.length > 0 && (
            <>
              <StudentDataFilter />
              <SearchFilterInput
                SearchContent={getSearchContent}
                data={assessmentList}
                userId={userId}
                type="allAssessment"
                domainId=""
                name={setInput}
              />
            </>
          )}
        </div>

        <div className="border-t pt-5 grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-5 lg:gap-10 pb-10">
          {isLoading ? (
            <>
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
            </>
          ) : paginatedData && paginatedData.length > 0 ? (
            paginatedData.map((data: any, i) => {
              const expiryCondition =
                data.expire < dateToday || data.expire == dateToday;
              // const remainDate = data.expire - dateToday;

              return (
                // <Link
                //   key={i}
                //   to={`${
                //     data.expire < dateToday || data.expire == dateToday
                //       ? ""
                //       : `/student/assesment/domain/${domainId}/category/${categoryId}/skill/${skillId}/assessment/${data.uuid}/user/${userId}/exam/instruction`
                //   }`}
                // >
                <Fragment key={i}>
                  <AssessmentCard
                    route={`${
                      data.expire < dateToday || data.expire == dateToday
                        ? ""
                        : `/student/assesment/${data.uuid}/exam/instruction`
                    }`}
                    active={setActive}
                    days={data.expire}
                    expire={true}
                    todayDate={dateToday}
                    id={data?.uuid}
                    image={data.image}
                    description={data.description}
                    url={data?.url}
                    user={userId}
                    admin={data?.userId}
                    type="radio"
                    onChange={getSkillId}
                    header={data?.name}
                    value={data?.uuid}
                    assessmentId={data?.assessmentId}
                    addResponse={setAddResponse}
                    status={data?.status}
                    duration={data?.duration}
                    name="default-radio"
                    className="w-6 h-6  appearance-none"
                    removeFav={(e) => {
                      removeFavourites(data?.uuid);
                    }}
                    addFav={() => {
                      addFavourites(data?.uuid);
                    }}
                  />
                </Fragment>
              );
            })
          ) : (
            <div className=" bg-white shadow-lg p-5 max-w-2xl mx-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 256 256"
                className="text-center text-green-600 h-24 w-24 mx-auto"
              >
                <rect width="256" height="256" fill="none"></rect>
                <circle
                  cx="128"
                  cy="128"
                  r="96"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="16"
                ></circle>
                <circle cx="92" cy="108" r="12"></circle>
                <circle cx="164" cy="108" r="12"></circle>
                <path
                  d="M169.6,152a48.1,48.1,0,0,1-83.2,0"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="16"
                ></path>
              </svg>
              <p className="text-xl font-semibold font-primary text-center">
                There are no assessment Created.
              </p>
            </div>
          )}
        </div>
        {paginatedData && getSearch && (
          <div
            className={`${
              assessmentList?.length >= 8
                ? paginatedData?.length >= 5
                  ? "mb-2"
                  : "absolute left-0 right-0 bottom-20 mb-2"
                : "mx-auto hidden"
            }`}
          >
            <PaginationData
              data={assessmentList}
              paginatedData={setPaginatedData}
              userId={userId}
              getSearchActive={isSearch}
              type="allAssessment"
              domainId=""
              name={input}
            />
          </div>
        )}
      </div>
    </>
  );
};
