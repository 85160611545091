import React, { useEffect, useState } from "react";
import {
  addAnswersList,
  deleteQuestion,
  questionAnswerCreate,
} from "../../../../../serviceApi/api";
import { InputField } from "../../../../../components/inputFields/InputField";
import { PrimaryButton } from "../../../../../components/buttons/PrimaryButton";

import { getOptions } from "../../../../../serviceApi/api";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { getCookie } from "../../../../../utils";
import Swal from "sweetalert2";
import { nameInputValid } from "../../../../../utils/validation";
import { Select, Form, Spin } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import SelectField from "../../../../../components/selectField/SelectField";

interface answersType {
  option: string;
  uuid: string;
}
export const MediaQuestions = () => {
  const navigate = useNavigate();
  const { skillId, assessmentId } = useParams();
  const [question, setQuestion] = useState("");
  const [questionImage, setQuestionImage] = useState<any>(null);
  const [questionImagePreview, setQuestionImagePreview] = useState<any>();
  const [answerImage, setAnswerImage] = useState<any>([]);
  const [answerImagePreview, setAnswerImagePreview] = useState<any>([]);
  const questionId = "";
  const [uniquesQuestionId] = useState("");
  const [isAnswerImage, setIsAnswerImage] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState<any>([]);
  const [weightage, setWeightage] = useState<any>("0");
  const [topic, setTopic] = useState("");
  const [optionsList, setOptionsList] = React.useState<any>([{}]);
  const [optionType, setOptionType] = useState("");
  const [formStatus, setFormStatus] = useState(true);
  const [topicError, setTopicError] = useState("");
  const [weightageErr, setWeightageErr] = useState("");
  const [hideUploadAnswer, setHideUploadAnswer] = useState(false);
  const [duplicateMessage, setDuplicateMessage] = React.useState(false);
  const [answersList, setAnswersList] = useState<any>([{}]);
  const [answerFormStatus, setAnswerFormStatus] = useState(true);
  const [textAnswerFormStatus, setTextAnswerFormStatus] = useState(true);
  const [selectKey, setSelectKey] = useState(0);
  const [apiDataLoader, setApiDataLoader] = useState(false);
  const [questionLevel, setQuestionLevel] = useState("");
  const handleAnswersImage = async (e: any) => {
    const list = [...optionsList];
    setAnswerFormStatus(true);
    e.preventDefault();
    const answerSelectedFiles = e.target.files[0];
    if (answerSelectedFiles?.size >= 2097152) {
      Swal.fire({
        icon: "warning",
        title: "Image size should be max 2MB.",
        showConfirmButton: false,
        timer: 3000,
      });
    } else if (
      answerSelectedFiles?.type === "image/png" ||
      answerSelectedFiles?.type === "image/jpg" ||
      answerSelectedFiles?.type === "image/jpeg" ||
      answerSelectedFiles?.type === "image/webp" ||
      answerSelectedFiles?.type === "image/jfif" ||
      answerSelectedFiles?.type === "image/svg"
    ) {
      const assessmentNewId: any = assessmentId;
      setAnswerImagePreview([URL.createObjectURL(answerSelectedFiles)]);
      const response = await addAnswersList({
        answerId: "",
        answer: answerSelectedFiles?.name,
      });
      setAnswerImage([...answerImage, response?.data.createAnswersList?.uuid]);
      if (response?.data.createAnswersList?.uuid) {
        const formData = new FormData();
        formData.append("image", answerSelectedFiles);
        formData.append("questionType", "Media");
        formData.append("assessmentId", assessmentNewId);
        formData.append("uuid", response?.data.createAnswersList?.uuid);
        formData.append("type", "option");
        const { data }: any = await axios.post(
          `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
          formData,
          {
            headers: {
              enctype: "multipart/form-data",
              Authorization: `Bearer ${getCookie("token")}`,
            },
          }
        );
        if (data?.status == true) {
          list[optionsList?.length == 0 ? 0 : optionsList?.length - 1] = {
            uuid: data?.uuid,
            option: `/option/${data?.uuid}/${answerSelectedFiles?.name}`,
          };
          setIsAnswerImage(true);
          setOptionsList(list);
          setAnswerFormStatus(false);
          setOptionType("");
        }
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Image formate should be png,jpg,jpeg,webp,jfif,svg",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const handleQuestionImage = async (e: any) => {
    e.preventDefault();
    const selectedFiles = e.target.files[0];
    if (selectedFiles.size >= 2097152) {
      Swal.fire({
        icon: "warning",
        title: "Image size should be max 2MB.",
        showConfirmButton: false,
        timer: 3000,
      });
    } else if (
      selectedFiles.type === "image/png" ||
      selectedFiles.type === "image/jpg" ||
      selectedFiles.type === "image/jpeg" ||
      selectedFiles.type === "image/webp" ||
      selectedFiles.type === "image/jfif" ||
      selectedFiles.type === "image/svg"
    ) {
      setQuestionImage(selectedFiles);
      setQuestionImagePreview(URL.createObjectURL(selectedFiles));
    } else {
      Swal.fire({
        icon: "warning",
        title: "Image formate should be png,jpg,jpeg,webp,jfif,svg",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };
  const deleteQuestionImage = async (e) => {
    e.preventDefault();
    setQuestionImagePreview(null);
  };

  const optionsData = answersList?.map(
    (list: { uuid: string; option: string }) => {
      if (list?.uuid) {
        return {
          value: list?.uuid,
          label: list?.option,
        };
      } else
        return {
          value: "",
          label: "",
        };
    }
  );

  const selectCorrectAnswer = (newValue: string) => {
    setCorrectAnswers(newValue);
  };

  const mediaDataSubmit = async (e: any) => {
    setApiDataLoader(true);
    e.preventDefault();
    setFormStatus(true);
    if (isAnswerImage) {
      const list = [...optionsList];

      for (const i in optionsList) {
        list[i] = {
          uuid: optionsList[i].uuid,
          option: optionsList[i].option,
        };
      }
      const response = await questionAnswerCreate({
        assessmentId,
        question: question,
        weightage,
        answers: list,
        CorrectAnswer: correctAnswers,
        topic,
        questionId: questionId,
        questionType: questionImagePreview ? "4" : "2",
        action: "create",
        questionLevel,
      });
      if (response?.data?.createQuestionAnswer?.id) {
        if (questionImagePreview) {
          const formData = new FormData();
          const assessmentNewId: any = assessmentId;
          formData.append("image", questionImage);
          formData.append("questionType", "Media");
          formData.append("assessmentId", assessmentNewId);
          formData.append("uuid", response?.data?.createQuestionAnswer?.uuid);
          formData.append("type", "question");
          const { data }: any = await axios.post(
            `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
            formData,
            {
              headers: {
                enctype: "multipart/form-data",
                Authorization: `Bearer ${getCookie("token")}`,
              },
            }
          );
          if (data?.status == true) {
            setApiDataLoader(false);
            navigate(
              `/subadmin/domain/skill/${skillId}/assessment/${assessmentId}/questions/list`
            );
          }
        }
        navigate(
          `/subadmin/domain/skill/${skillId}/assessment/${assessmentId}/questions/list`
        );
      }
    } else {
      const list = [...answersList];
      for (const i in answersList) {
        list[i] = {
          uuid: answersList[i].uuid,
          option: answersList[i].option,
        };
      }
      const response = await questionAnswerCreate({
        assessmentId,
        question: question,
        weightage,
        answers: list,
        CorrectAnswer: correctAnswers,
        topic,
        questionId: questionId,
        questionType: "3",
        action: "create",
        questionLevel,
      });
      if (response?.data?.createQuestionAnswer?.id) {
        getOptionsByQuestionId(uniquesQuestionId);
        setOptionType("text");
        const formData = new FormData();
        const assessmentNewId: any = assessmentId;
        formData.append("image", questionImage);
        formData.append("questionType", "Media");
        formData.append("assessmentId", assessmentNewId);
        formData.append("uuid", response?.data.createQuestionAnswer?.uuid);
        formData.append("type", "question");
        const { data }: any = await axios.post(
          `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
          formData,
          {
            headers: {
              enctype: "multipart/form-data",
              Authorization: `Bearer ${getCookie("token")}`,
            },
          }
        );
        if (data?.status == true) {
          navigate(
            `/subadmin/domain/skill/${skillId}/assessment/${assessmentId}/questions/list`
          );
        } else {
          console.log("error");
        }
      }
    }
  };

  const getOptionsByQuestionId = async (questionId: string) => {
    const response = await getOptions(questionId);
    setOptionsList(response?.data?.quesionsOptions);
  };

  const addInputField = () => {
    setAnswersList([...answersList, {}]);
    setTextAnswerFormStatus(true);
  };

  const addImageField = () => {
    setOptionsList([...optionsList, {}]);
    setAnswerFormStatus(true);
  };

  const deleteAnswerImage = async (e: any, img: any) => {
    setCorrectAnswers(correctAnswers.filter((item: any) => item !== img?.uuid));
    const formData = new FormData();
    const assessmentNewId: any = assessmentId;
    formData.append("image", img?.option);
    formData.append("questionType", "Media");
    formData.append("assessmentId", assessmentNewId);
    formData.append("uuid", img?.uuid);
    formData.append("type", "option");
    const { data }: any = await axios.post(
      `${process.env.REACT_APP_MEDIA_URL}/api/media/delete`,
      formData,
      {
        headers: {
          enctype: "multipart/form-data",
          Authorization: `Bearer ${getCookie("token")}`,
        },
      }
    );
    if (data?.status == true) {
      await deleteQuestion(img?.uuid, "option");
      setOptionsList(
        optionsList.filter((item: string | any) => {
          if (item?.option) {
            return item?.option !== img?.option;
          } else return item !== img;
        })
      );
      setAnswerImage(
        answerImage.filter((item) => {
          if (item) {
            return item !== img?.uuid;
          } else return item !== img?.uuid;
        })
      );
    }
    if (optionsList?.length == 0) {
      setAnswerFormStatus(true);
      setOptionType("");
    }
  };

  const removeInputFields = async (value, index: number) => {
    setFormStatus(true);
    setTextAnswerFormStatus(true);
    setCorrectAnswers([]);
    if (value?.uuid) {
      try {
        const result = await deleteQuestion(value?.uuid, "option");
        if (result) {
          setSelectKey((prevKey) => prevKey + 1);
          const list = [...answersList];
          list.splice(index, 1);
          setAnswersList(list);
          setTextAnswerFormStatus(false);
        }
      } catch (error: any) {
        throw new Error(error);
      }
    } else {
      setSelectKey((prevKey) => prevKey + 1);
      const list = [...answersList];
      list.splice(index, 1);
      setAnswersList(list);
      setTextAnswerFormStatus(false);
    }
  };

  const handleChange = (event, index: number) => {
    setOptionType("text");
    const { value } = event.target;
    const list = [...answersList];
    list[index] = { uuid: "", option: value };
    setAnswersList(list);
    if (value?.length == 0 && answersList?.length == 1) {
      setOptionType("");
      setHideUploadAnswer(false);
    }
    setIsAnswerImage(false);
  };

  const selectCorrectAnswers = (e) => {
    const { checked, value } = e?.target;
    if (checked) {
      setCorrectAnswers([...correctAnswers, value]);
    } else if (!checked) {
      setCorrectAnswers(correctAnswers.filter((ans: string) => ans !== value));
    }
  };

  const addAnswers = async (index: number, e: any, uuid: string) => {
    const answer = e.target.value;
    const list = [...answersList];
    if (!answer?.replace(/\s/g, "").length) {
      setTextAnswerFormStatus(true);
    } else {
      const map = {};
      let result = false;
      const answeroptionArr: any = [];
      for (let i = 0; i < answersList?.length; i++) {
        answeroptionArr.push(answersList[i]?.option && answersList[i]?.option);
        const value = answeroptionArr[i] && answeroptionArr[i]?.toLowerCase();
        if (map[value]) {
          result = true;
          break;
        }
        map[value] = true;
      }
      if (result) {
        setDuplicateMessage(true);
        setTextAnswerFormStatus(true);
        setFormStatus(true);
      } else {
        setDuplicateMessage(false);
        if (uuid) {
          list[index] = {
            uuid: uuid,
            option: answer,
          };
          setSelectKey((prevKey) => prevKey + 1);
          setAnswersList(list);
          setCorrectAnswers([]);
          setTextAnswerFormStatus(false);
        } else {
          const response = await addAnswersList({
            answerId: "",
            answer,
          });

          if (response?.data?.createAnswersList) {
            list[index] = {
              uuid: response?.data?.createAnswersList?.uuid,
              option: response?.data?.createAnswersList?.option,
            };
            setSelectKey((prevKey) => prevKey + 1);
            setAnswersList(list);
            setCorrectAnswers([]);
            setTextAnswerFormStatus(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (answersList.length >= 2) {
      const checkanswer = answersList?.every(
        (item: any) => item?.option?.trim()?.length > 0
      );
      if (!checkanswer) {
        setFormStatus(true);
        setHideUploadAnswer(false);
        setHideUploadAnswer(true);
      } else {
        setHideUploadAnswer(true);
        if (
          Number(weightage) <= 10 &&
          Number(weightage) >= 1 &&
          weightage &&
          nameInputValid(topic) &&
          !duplicateMessage &&
          topic?.trim().length <= 60 &&
          topic?.trim().length >= 2 &&
          topic &&
          (question?.trim()?.length > 0 || questionImagePreview) &&
          (optionsList?.length >= 2 || answersList?.length >= 2) &&
          correctAnswers?.length >= 1
        ) {
          setFormStatus(false);
        } else {
          setFormStatus(true);
        }
      }
    }
  }, [
    answersList,
    weightage,
    topic,
    question,
    answersList?.length,
    correctAnswers?.length,
    questionImagePreview,
  ]);

  useEffect(() => {
    if (optionsList.length >= 2) {
      const checkanswer = optionsList.every(
        (item: any) => item?.option?.trim()?.length > 0
      );
      if (!checkanswer) {
        setFormStatus(true);
      } else {
        if (
          Number(weightage) <= 10 &&
          Number(weightage) >= 1 &&
          weightage &&
          nameInputValid(topic) &&
          !duplicateMessage &&
          topic?.trim().length <= 60 &&
          topic?.trim().length >= 2 &&
          topic &&
          (question?.trim()?.length > 0 || questionImagePreview) &&
          (optionsList?.length >= 2 || answersList?.length >= 2) &&
          correctAnswers?.length >= 1
        ) {
          setFormStatus(false);
        } else {
          setFormStatus(true);
        }
      }
    } else {
      if (answersList?.length >= 2) {
        const checkanswer = answersList?.every(
          (item: any) => item?.option?.trim()?.length > 0
        );
        if (!checkanswer) {
          setFormStatus(true);
          setHideUploadAnswer(false);
          setHideUploadAnswer(true);
        } else {
          setHideUploadAnswer(true);
          if (
            Number(weightage) <= 10 &&
            Number(weightage) >= 1 &&
            weightage &&
            nameInputValid(topic) &&
            !duplicateMessage &&
            topic?.trim().length <= 60 &&
            topic?.trim().length >= 2 &&
            topic &&
            (question?.trim()?.length > 0 || questionImagePreview) &&
            (optionsList?.length >= 2 || answersList?.length >= 2) &&
            correctAnswers?.length >= 1
          ) {
            setFormStatus(false);
          } else {
            setFormStatus(true);
          }
        }
      } else {
        setFormStatus(true);
      }
    }
  }, [
    weightage,
    topic,
    question,
    optionsList,
    correctAnswers?.length,
    questionImagePreview,
  ]);

  const deleteImageField = async (e, index) => {
    optionsList.splice(index, 1);
    setAnswerFormStatus(false);
  };
  const options = [
    {
      value: "Easy",
      label: "Easy",
    },
    {
      value: "Medium",
      label: "Medium",
    },
    {
      value: "Difficult",
      label: "Difficult",
    },
  ];

  const handleQuestionLevel = (newValue: any) => {
    console.log("newValue", newValue);
    setQuestionLevel(newValue);
  };

  return (
    <>
      <div className="mb-10 px-5 flex flex-col lg:flex-col space-x-5 h-full mx-auto">
        {/* basic info */}
        <form onSubmit={mediaDataSubmit}>
          <div className="grid grid-cols-3 gap-x-10 pr-7">
            <div className="relative">
              <InputField
                id="Weightage"
                type="number"
                placeholder="Weightage"
                onChange={setWeightage}
                containsWeightageMsg={setWeightageErr}
              />
              <span className="text-red-500 mt-[6px] absolute">
                {weightageErr}
              </span>
            </div>
            <div className="relative">
              <InputField
                id="Topic Name"
                type="topic"
                placeholder="Topic"
                onChange={setTopic}
                containsSpecialCharsMsg={setTopicError}
              />
              <span className="text-red-500  mt-[6px] absolute">
                {topicError}
              </span>
            </div>
            <div className="relative">
              <SelectField
                label="select question level"
                options={options}
                showSearch
                placeholder="Select Question Level"
                onChange={(e) => handleQuestionLevel(e)}
              />
            </div>
          </div>

          <div className="px-0 grid grid-cols-2 gap-x-10 gap-y-5 mt-5 pr-6">
            <div className="lg:mt-2">
              {!questionImagePreview && optionType !== "text" ? (
                <div className="relative">
                  <label htmlFor="Question">
                    Question <span className="text-red-600">*</span>
                  </label>
                  <textarea
                    id="Question"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder="Enter Question"
                    className="lg:p-4 p-2 text-sm lg:mt-2 border border-gray-300 focus:outline-none w-full rounded-lg"
                  ></textarea>
                </div>
              ) : null}
              <p
                className={`text-center my-3 text-xl ${
                  question || questionImagePreview || optionType == "text"
                    ? "hidden"
                    : "block"
                }`}
              >
                Or
              </p>

              {!question ? (
                <>
                  {questionImagePreview ? (
                    <>
                      <p className="font-primary capitalize text-sm lg:text-base">
                        Question <span className="text-red-600">*</span>
                      </p>
                      <div className="h-64 border relative  mb-10 w-64">
                        <img
                          className="h-full w-full p-1 rounded-lg object-cover object-center"
                          src={questionImagePreview}
                          alt={questionImagePreview}
                        />
                        <div
                          onClick={(e) => deleteQuestionImage(e)}
                          className="bg-black cursor-pointer  w-8 h-8 flex items-center justify-center absolute top-1 right-1 rounded-full"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 mx-auto text-white"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                          </svg>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {optionType == "text" ? (
                        <p className="font-primary capitalize text-sm lg:text-base">
                          Question <span className="text-red-600">*</span>
                        </p>
                      ) : (
                        ""
                      )}
                      {/* input for upload image question start */}
                      <div className="w-44 h-44 flex items-center justify-center">
                        <label
                          htmlFor="dropzone-file"
                          data-testid="questionTestId"
                          className={`flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg  ${
                            question != ""
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          } bg-gray-50  hover:bg-gray-100`}
                        >
                          <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg
                              aria-hidden="true"
                              className="w-10 h-10 mb-3 text-gray-400"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              ></path>
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload
                              </span>
                            </p>
                            <p className="text-xs text-center text-gray-500 dark:text-gray-400">
                              SVG, PNG, JPG or GIF (MAX. 800x400px)
                            </p>
                          </div>
                          <input
                            id="dropzone-file"
                            type="file"
                            disabled={question != "" ? true : false}
                            onChange={handleQuestionImage}
                            className="hidden"
                          />
                        </label>
                      </div>
                      {/* end of upload image type question */}
                    </>
                  )}
                </>
              ) : null}
            </div>

            <div>
              {answerImage?.length == 0 && question === "" ? (
                <div className="w-full">
                  {answersList?.map((data, index: number) => {
                    return (
                      <div
                        key={data.id}
                        className="flex  flex-row justify-between w-full items-center relative"
                      >
                        <div
                          className={`relative w-full ${
                            index === 0 ? "mt-2" : "mt-5"
                          }`}
                        >
                          <label
                            className="font-primary capitalize text-sm lg:text-base"
                            htmlFor="answerSelect"
                          >
                            Answer <span className="text-red-600">*</span>
                          </label>
                          <input
                            id="answerSelect"
                            type="text"
                            name="answers"
                            onChange={(event) => handleChange(event, index)}
                            onBlur={(e) =>
                              addAnswers(
                                index,
                                e,
                                typeof data === "object" ? "" : data
                              )
                            }
                            value={data?.option ? data?.option : ""}
                            placeholder="Answer"
                            className="lg:p-4 p-2 text-sm lg:mt-2 border border-gray-300 focus:outline-none w-full rounded-lg"
                          />
                        </div>
                        {answersList?.length > 1 ? (
                          <svg
                            onClick={() =>
                              removeInputFields(data ? data : "", index)
                            }
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="right-14 w-6 h-6  ml-1 mt-10 cursor-pointer"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        ) : // </div>
                        null}
                      </div>
                    );
                  })}
                  {duplicateMessage && duplicateMessage ? (
                    <p className="text-xs text-red-500 p-0 mb-0 mt-2">
                      Answer option should be unique!
                    </p>
                  ) : null}
                  {answersList?.length <= 5 && (
                    <button
                      disabled={textAnswerFormStatus ? true : false}
                      className={`${
                        textAnswerFormStatus
                          ? "bg-gray-200  text-gray-400  cursor-not-allowed"
                          : "bg-brand-500 text-white cursor-pointer"
                      } w-32 text-center mt-5 rounded-lg font-primary  p-2`}
                      onClick={() => addInputField()}
                    >
                      Add New
                    </button>
                  )}
                  {optionType == "text" && answersList?.length < 2 && (
                    <div className="text-black mt-2 border-spacing-1 border-l-8 bg-orange-300 shadow-sm height-2">
                      <QuestionCircleOutlined />
                      <span className="ml-2">
                        Important: Answers options should be two or more than
                        two
                      </span>
                    </div>
                  )}
                </div>
              ) : null}
              {optionType == "text" ? (
                ""
              ) : (
                <>
                  <p
                    className={`text-center my-3 text-xl ${
                      answerImage?.length >= 1 || question != "" ? "hidden" : ""
                    }`}
                  >
                    Or
                  </p>
                  {!hideUploadAnswer && (
                    <div>
                      <p className="mt-2 lg:mt-2">
                        Upload Answers <span className="text-red-600">*</span>
                      </p>
                      {/* preview div for answers image type */}
                      <div className="grid grid-cols-3 lg:grid-cols-2 gap-10 lg:gap-5">
                        {optionsList?.length > 0
                          ? optionsList?.map((img: any, index: number) => {
                              return (
                                <div key={index}>
                                  {img?.option ? (
                                    <div className="h-44 border relative  mb-10 w-full">
                                      <img
                                        className="h-full w-full p-1 rounded-lg object-cover object-center"
                                        src={`${process.env.REACT_APP_MEDIA_URL}/api/media/download${img?.option}`}
                                        alt={img?.option}
                                      />
                                      <div
                                        onClick={(e) =>
                                          deleteAnswerImage(e, img)
                                        }
                                        className="bg-black hover:bg-red-500 cursor-pointer  w-8 h-8 flex items-center justify-center absolute top-1 right-1 rounded-full"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="w-5 h-5 mx-auto text-white"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      <div className="h-44 w-44 flex items-center justify-center relative">
                                        <label
                                          htmlFor="dropzone-file-answer"
                                          data-testid="QuestionAnswerId"
                                          className="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                        >
                                          <div className=" flex flex-col items-center justify-center pt-5 pb-6">
                                            <svg
                                              aria-hidden="true"
                                              className="w-10 h-10 mb-3 text-gray-400"
                                              fill="none"
                                              stroke="currentColor"
                                              viewBox="0 0 24 24"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                              ></path>
                                            </svg>
                                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400 text-center">
                                              <span className="font-semibold">
                                                Click to upload
                                              </span>
                                            </p>
                                            <p className="text-xs text-gray-500 dark:text-gray-400 text-center">
                                              SVG, PNG, JPG or GIF (MAX.
                                              800x400px)
                                            </p>
                                          </div>
                                          <input
                                            id="dropzone-file-answer"
                                            type="file"
                                            onChange={(e) =>
                                              handleAnswersImage(e)
                                            }
                                            className="hidden"
                                          />
                                        </label>
                                        {optionsList?.length > 1 && (
                                          <div
                                            onClick={(e) =>
                                              deleteImageField(e, index)
                                            }
                                            className="bg-black hover:bg-red-500 cursor-pointer  w-8 h-8 flex items-center justify-center absolute top-0 right-0 rounded-full"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth={1.5}
                                              stroke="currentColor"
                                              className="w-5 h-5 mx-auto text-white"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                              />
                                            </svg>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                              );
                            })
                          : null}
                      </div>
                      {/* end  of preview div for answers image type */}

                      {/* answer input field end */}
                      {/* {answerFormStatus
                        ? answersList?.map((list, index) => {
                            return (
                              <>
                                <div className="h-44 w-44 flex items-center justify-center relative">
                                  <label
                                    htmlFor="dropzone-file-answer"
                                    data-testid="QuestionAnswerId"
                                    className="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                  >
                                    <div className=" flex flex-col items-center justify-center pt-5 pb-6">
                                      <svg
                                        aria-hidden="true"
                                        className="w-10 h-10 mb-3 text-gray-400"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                        ></path>
                                      </svg>
                                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400 text-center">
                                        <span className="font-semibold">
                                          Click to upload
                                        </span>
                                      </p>
                                      <p className="text-xs text-gray-500 dark:text-gray-400 text-center">
                                        SVG, PNG, JPG or GIF (MAX. 800x400px)
                                      </p>
                                    </div>
                                    <input
                                      id="dropzone-file-answer"
                                      type="file"
                                      onChange={(e) => handleAnswersImage(e)}
                                      className="hidden"
                                    />
                                  </label>
                                  {optionsList?.length > 1 && (
                                    <div
                                      onClick={(e) =>
                                        deleteImageField(e, index)
                                      }
                                      className="bg-black hover:bg-red-500 cursor-pointer  w-8 h-8 flex items-center justify-center absolute top-0 right-0 rounded-full"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-5 h-5 mx-auto text-white"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                        />
                                      </svg>
                                    </div>
                                  )}
                                </div>
                              </>
                            );
                          })
                        : ""} */}
                      {optionsList?.length <= 5 ? (
                        <button
                          disabled={answerFormStatus ? true : false}
                          className={`${
                            answerFormStatus
                              ? "bg-gray-200  text-gray-400  cursor-not-allowed"
                              : "bg-brand-500 text-white cursor-pointer"
                          } w-32 text-center mt-5 rounded-lg font-primary  p-2`}
                          onClick={() => addImageField()}
                        >
                          Add New
                        </button>
                      ) : (
                        ""
                      )}

                      {optionsList?.length < 2 && (
                        <div className="text-black mt-2 border-spacing-1 border-l-8 bg-orange-300 shadow-sm height-2">
                          <QuestionCircleOutlined />
                          <span className="ml-2">
                            Important: Answers options should be two or more
                            than two
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className={`${optionType !== "text" ? "w-full" : "w-1/2"}`}>
            {/* correct answers images */}
            {answersList?.length > 0 || optionsList?.length >= 0 ? (
              <>
                <div className="">
                  {answerImage?.length > 0 ? (
                    <p className="my-5">
                      Select correct Answers{" "}
                      <span className="text-red-600">*</span>
                    </p>
                  ) : (
                    ""
                  )}
                  <div
                    className={`${
                      optionType !== "text"
                        ? "grid grid-cols-6 lg:grid-cols-5 gap-5"
                        : "w-full"
                    }`}
                  >
                    {answersList.length > 0 && optionType == "text" ? (
                      // <div key={item?.uuid}>
                      <Form.Item
                        label="Correct Answer"
                        name="CorrectAnswer"
                        className="correctAnswer"
                        rules={[
                          {
                            required: true,
                            message: "Please Select correct answer.",
                          },
                        ]}
                      >
                        <Select
                          // showSearch
                          key={selectKey}
                          onChange={selectCorrectAnswer}
                          mode="multiple"
                          placeholder="Search / Select Correct answers"
                          optionFilterProp="children"
                          style={{
                            width: "99%",
                          }}
                          filterOption={(input, option: any) =>
                            (option?.label ?? "")?.includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              ?.toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "")?.toLowerCase()
                              )
                          }
                          options={optionsData}
                        />
                      </Form.Item>
                    ) : optionType !== "text" ? (
                      optionsList?.map((item: answersType) => {
                        return (
                          <>
                            {typeof item?.option != "undefined" ? (
                              <label
                                key={item?.uuid}
                                className="checkbox-image inline-block relative cursor-pointer ml-[30px] mr-[15px] mb-[200px]"
                              >
                                <input
                                  type="checkbox"
                                  onChange={selectCorrectAnswers}
                                  value={item?.uuid}
                                  name="checkbox-name"
                                  checked={correctAnswers?.includes(item?.uuid)}
                                />
                                <img
                                  src={`${process.env.REACT_APP_MEDIA_URL}/api/media/download${item?.option}`}
                                  alt="optio-image"
                                />
                              </label>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })
                    ) : null}
                  </div>
                </div>
              </>
            ) : null}
            {/* correct answers images */}
          </div>

          <div className="mt-5 mb-10">
            <PrimaryButton
              disabledStatus={formStatus ? true : false}
              type="submit"
              text="Submit"
              onclick={() => {
                mediaDataSubmit;
              }}
              className={`${
                formStatus
                  ? "bg-gray-300 cursor-not-allowed border-gray-300"
                  : "bg-black hover:bg-transparent hover:border-black border-black hover:text-black"
              }`}
            />
          </div>
        </form>
        {apiDataLoader && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Spin tip="Loading..."></Spin>
          </div>
        )}
      </div>
    </>
  );
};
